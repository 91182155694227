import React, { useEffect, useState } from "react";
import { Application } from "./ProjectApplication";
import { useGetProjectApplication } from "../useGetProjectApplication";
import toast from "react-hot-toast";
import {
  FileText,
  Globe,
  Phone,
  Mail,
  Briefcase,
  CreditCard,
  BarChart,
  MapPin,
  Image,
  Loader2,
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { response } from "express";

const ProjectDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { getProjectById } = useGetProjectApplication();
  const [formData, setFormData] = useState<Application>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await getProjectById(String(projectId));
        console.log("RESPONSE IN BY ID : ", response);
        setFormData(response);
      } catch (error) {
        console.log("Some Error occured While Fetching project detail by ID");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  const onBack = () => {
    navigate("/projectapplication");
  };

  const {
    rejectApplication,
    acceptApplication,
    approvalLoading,
    rejectLoader,
  } = useGetProjectApplication();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [reason, setReason] = useState("");

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="flex items-start  text-2xl justify-center h-screen">
        No Data Found , Please check the Project ID again .
      </div>
    );
  }

  const handleAction = async () => {
    setIsProcessing(true);
    const actionFn =
      actionType === "approve" ? acceptApplication : rejectApplication;

    try {
      const response = await actionFn(formData._id, reason); // Pass reason if rejecting

      toast.success(
        actionType === "approve"
          ? "Project approved successfully!"
          : "Project rejected successfully!",
      );
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      onBack();
      setIsProcessing(false);
      setShowConfirmationModal(false);
      setActionType(null);
      setReason(""); // Clear reason after processing
    }
  };

  const initiateAction = (type: "approve" | "reject") => {
    setActionType(type);
    setShowConfirmationModal(true);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-orange-500";
      default:
        return "";
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Project Details
          <span
            className={getStatusColor(formData.approvalStatus) + " text-sm"}
          >
            {"        (" + formData.approvalStatus + ")"}
          </span>
        </h1>
        <p className="text-gray-500">
          Review the selected project details below
        </p>
      </header>

      <section className="space-y-6">
        {/* Project Information */}
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Briefcase className="w-5 h-5 mr-2 text-emerald-600" />
            Project Information
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Project Logo</label>
                <img
                  src={formData.logo}
                  alt="project logo"
                  className="w-20 h-20 object-cover rounded"
                />
              </div>
              <div>
                <label className="text-gray-500 text-sm">Project Name</label>
                <p className="font-medium">{formData.name}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Mail className="w-4 h-4 mr-1" />
                  Email Address
                </label>
                <p className="font-medium">{formData.email}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Phone className="w-4 h-4 mr-1" />
                  Phone Number
                </label>
                <p className="font-medium">{formData.phoneNumber}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Project Symbol</label>
                <p className="font-medium">{formData.symbol}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Globe className="w-4 h-4 mr-1" />
                  Website
                </label>
                <a
                  href={formData.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-emerald-600 hover:underline"
                >
                  {formData.website}
                </a>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Description</label>
                <p className="font-medium line-clamp-2">
                  {formData.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Financial Details */}
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <CreditCard className="w-5 h-5 mr-2 text-emerald-600" />
            Financial Details
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">
                  Required Investment
                </label>
                <p className="font-medium">{formData.requiredInvestment}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Percentage To Burn
                </label>
                <p className="font-medium">{formData.burn}%</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Min Investment</label>
                <p className="font-medium">{formData.minInvestment}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Lock In Period</label>
                <p className="font-medium">{formData.lockInPeriod}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Max Token Supply
                </label>
                <p className="font-medium">{formData.maxSupply}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Accepted Currencies
                </label>
                <div className="flex flex-wrap gap-2 mt-1">
                  {formData.currenciesAccepted?.length > 0 ? (
                    formData.currenciesAccepted.map((currency) => (
                      <span
                        key={currency._id}
                        className="px-2 py-1 bg-emerald-100 text-emerald-700 rounded text-sm"
                      >
                        {currency.name}
                      </span>
                    ))
                  ) : (
                    <span className="text-gray-500">
                      No currencies selected
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Market Details */}
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <BarChart className="w-5 h-5 mr-2 text-emerald-600" />
            Market Details
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Project Type</label>
                <p className="font-medium">{formData.projectType}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Project Stage</label>
                <p className="font-medium">{formData.projectStage}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <MapPin className="w-4 h-4 mr-1" />
                  Geographic Market
                </label>
                <p className="font-medium">{formData.geographicMarket}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FileText className="w-4 h-4 mr-1" />
                  Documents
                </label>
                <div className="space-y-2">
                  {formData.balanceSheet && (
                    <a
                      href={formData.balanceSheet}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-emerald-600 hover:underline"
                    >
                      Balance Sheet
                    </a>
                  )}
                  {formData?.otherDocument?.map((doc, index) => (
                    <a
                      key={index}
                      href={doc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-emerald-600 hover:underline"
                    >
                      Document {index + 1}
                    </a>
                  ))}
                </div>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Image className="w-4 h-4 mr-1" />
                  Images
                </label>
                <div className="flex gap-2 mt-2">
                  {formData?.images?.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Project image ${index + 1}`}
                      className="w-20 h-20 object-cover rounded"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex items-center justify-between pt-4 border-t">
          <button
            onClick={onBack}
            disabled={approvalLoading || rejectLoader}
            className="text-emerald-600 hover:underline"
          >
            ← Back
          </button>

          {formData.approvalStatus === "pending" && (
            <div className="space-x-3">
              <button
                onClick={() => initiateAction("approve")}
                disabled={approvalLoading || rejectLoader}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50"
              >
                Approve
              </button>

              <button
                onClick={() => initiateAction("reject")}
                disabled={approvalLoading || rejectLoader}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50"
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Confirmation Modal */}
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to {actionType} this application?
            </p>

            {/* Show input field for rejection reason if actionType is 'reject' */}
            {actionType === "reject" && (
              <div className="mb-4">
                <label className="text-sm text-gray-500">
                  Reason for rejection
                </label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Provide a reason"
                  className="w-full p-2 mt-2 border rounded-md"
                  rows={4}
                />
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                disabled={isProcessing}
                onClick={() => {
                  setShowConfirmationModal(false);
                  setActionType(null);
                  setReason(""); // Clear reason when modal is closed
                }}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                disabled={isProcessing}
                onClick={handleAction}
                className={`px-4 py-2 rounded-lg relative ${
                  actionType === "approve"
                    ? "bg-emerald-600 hover:bg-emerald-700"
                    : "bg-red-500 hover:bg-red-600"
                } text-white disabled:opacity-50`}
              >
                {isProcessing ? (
                  <span className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
