import React from "react";
import SettingIcon2 from "../../../images/Settings/setting2.png";
import SettingIcon4 from "../../../images/Settings/setting4.png";
import { Link } from "react-router-dom";

const Settings = () => {
  return (
    <>
      <div className="bg-[#fff] p-6 rounded-[16px] h-full">
        <h1 className="font-semibold text-[22px]">Settings</h1>
        <p className="text-[#9e9b9b] text-[14px]">
          Manage your account, preferences, and security settings. Customize
          your experience, update personal details, and control how you interact
          with the platform for a seamless and secure journey.
        </p>
        <div className="grid grid-cols-12 mt-12 gap-6">
          {/* Notification Card */}
          <div className="col-span-6">
            <Link to="/settings/notification">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img
                    className="h-[24px] w-[24px]"
                    src={SettingIcon2}
                    alt="Notification Icon"
                  />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">
                    Notification
                  </h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Stay updated with real-time notifications. Get alerts on
                    investments, project updates, and important platform
                    activity to ensure you never miss a key moment.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          {/* Preference Card */}
          <div className="col-span-6">
            <Link to="/settings/preference">
              <div className="card bg-[#fafafa] p-4 flex items-center border  rounded-[16px] hover:shadow-lg transition-shadow duration-300">
                <div className="flex-shrink-0 h-[65px] w-[65px] bg-[#52bbab] rounded-full flex items-center justify-center">
                  <img
                    className="h-[24px] w-[24px]"
                    src={SettingIcon4}
                    alt="Preference Icon"
                  />
                </div>
                <div className="ml-4">
                  <h2 className="text-[20px] text-[#000] font-semibold">
                    Preference
                  </h2>
                  <p className="mt-1 text-[14px] text-[#9e9b9b]">
                    Customize your platform experience. Adjust notification
                    settings, privacy options, and display preferences to suit
                    your personal needs and enhance your journey.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
