import React, { useState, ReactNode } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ApiService from "../service/ApiService";
import { apiPaths } from "../service/apiPaths";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";

const DefaultLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [logOutModal, setLogOutModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogOutModal(false);
    logOutHandler();
  };

  const cancelLogout = () => {
    setLogOutModal(false);
  };
  const logOutHandler = async () => {
    try {
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.Logout,
      });
      console.log(response);
      dispatch(setIsLoggedIn(false));
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/login");
    }
  };

  return (
    <>
      <section className="layout-section min-h-full">
        <Header />
        <div className="grid grid-cols-12 bg-[#e8eef1]  min-h-full">
          <div className="col-span-12 lg:col-span-3 p-6 min-h-full relative">
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              setLogOutModal={setLogOutModal}
            />
          </div>
          <div className="col-span-12 lg:col-span-9 min-h-full p-6 pl-0 bg-[#e8eef1]">
            <main className="h-full">
              <div className="mx-auto max-w-screen-2xl h-full">{children}</div>
            </main>
          </div>
        </div>

        {logOutModal && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-[400px]">
              <h2 className="text-[20px] font-semibold mb-4">
                Do you wish to logout?
              </h2>
              <div className="flex justify-end gap-4">
                <button
                  className="bg-gray-300 text-white px-4 py-2 rounded-md"
                  onClick={cancelLogout}
                >
                  No
                </button>
                <button
                  className="bg-[#52bbab] text-white px-4 py-2 rounded-md"
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default DefaultLayout;
