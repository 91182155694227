import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  return (
    <>
      <div className="bg-[#fff] p-6 rounded-[16px] min-h-full">
        <h1 className="font-semibold text-[22px]">Dashboard</h1>
      </div>
    </>
  );
};

export default Dashboard;
