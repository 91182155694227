import React, { useEffect, useState } from "react";
import ProfileImage from "../../../images/icons/profileimg.png";
import EditIcon from "../../../images/icons/edit.png";
import { useProfile } from "../useProfile";
import { ProfileFormData, ProfileResponse } from "../Profile.props";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/userSlice";
import { RootState } from "../../../redux/appStore";
import toast from "react-hot-toast";
import { apiPaths } from "../../../service/apiPaths";
import ApiService from "../../../service/ApiService";

const Profile = () => {
  const { getProfile, updateProfile, updateProfilePic, changePassword } =
    useProfile();
  const dispatch = useDispatch();
  const [phoneCountryCode, setPhoneCountryCode] = useState("+91");

  const user = useSelector((store: RootState) => store?.user?.user);

  const [profileData, setProfileData] = useState({
    profilePic: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    firstname: "",
    lastname: "",
  });

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(
    user ? user.profilePic : null,
  );
  const [imageFile, setImageFile] = useState<File | null>(null);

  const setProfile = (response: ProfileResponse) => {
    setProfileData({
      profilePic: response.profilePic,
      fullName: response.fullName,
      email: response.email,
      phoneNumber: response.phoneNumber,
      dateOfBirth: response.dateOfBirth,
      city: response.city,
      state: response.state,
      country: response.country,
      postalCode: response.postalCode,
      firstname: response.firstname,
      lastname: response.lastname,
    });
  };

  useEffect(() => {
    if (user && profileData.fullName === "") {
      setProfile(user);
    }
  }, [user]);

  const phoneCountryCodes = ["+91", "+1", "+49"];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProfilePictureChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      dispatch(setUser({ ...user, profilePic: imageUrl }));
      setImageFile(file);
    }
  };

  const saveProfile = async () => {
    let returnedImage = "";
    const formattedPhoneNumber = profileData?.phoneNumber?.startsWith(
      phoneCountryCode,
    )
      ? profileData.phoneNumber
      : phoneCountryCode + profileData.phoneNumber;

    // Check if a new profile picture was selected
    if (imageFile) {
      const formData = new FormData();
      formData.append("files", imageFile);

      const uploadResponse = await updateProfilePic(formData);

      if (uploadResponse && uploadResponse.files?.length) {
        returnedImage = uploadResponse.files[0];
        console.log("URL FOR UPLOADED IMAGE : ", returnedImage);
      } else {
        toast.error("Error uploading profile picture");
        return;
      }
    }

    // Call the updateProfile method with the profile data
    const updatedProfileData = {
      ...profileData,
      phoneNumber: formattedPhoneNumber,
      profilePic: returnedImage,
    };
    console.log("PROFILE DATA TO SEND : ", updatedProfileData);

    const response = await updateProfile(updatedProfileData);

    if (response) {
      console.log("REsponse from update profile : ", response);
      setSelectedImage(response.profilePic);
      setProfile(response);
      dispatch(setUser(response));
      toast.success("Profile updated successfully");
    } else {
      toast.error("Error updating profile");
      console.log("Error updating profile");
    }
  };

  useEffect(() => {
    if (user?.phoneNumber) {
      const phoneNumber = user.phoneNumber;
      const matchedCode = phoneCountryCodes.find((code) =>
        phoneNumber.startsWith(code),
      );

      if (matchedCode) {
        setPhoneCountryCode(matchedCode);
        setProfileData((prev) => ({
          ...prev,
          phoneNumber: phoneNumber.slice(matchedCode.length),
        }));
      }
    }
  }, [user]);
  const handleChangePassword = async () => {
    // Validate passwords
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.error("New passwords do not match");
      return;
    }

    if (passwordData.newPassword.length < 8) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    setIsPasswordChanging(true);
    try {
      const response = await changePassword(
        passwordData.oldPassword,
        passwordData.newPassword,
      );
      console.log(response);

      if (response) {
        toast.success("Password changed successfully");
        // Reset password fields
        setPasswordData({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setIsPasswordModalOpen(false);
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      toast.error("An error occurred while changing password");
    } finally {
      setIsPasswordChanging(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <>
      {/* Password Change Modal */}
      {isPasswordModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-[16px] w-[400px] relative">
            <button
              onClick={() => setIsPasswordModalOpen(false)}
              className="absolute top-4 right-4 text-[20px] text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
            <h2 className="text-[20px] font-semibold mb-4">Change Password</h2>
            <div className="space-y-4">
              <div>
                <p className="mb-2">Old Password</p>
                <input
                  type="password"
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Enter old password"
                />
              </div>
              <div>
                <p className="mb-2">New Password</p>
                <input
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Enter new password"
                />
              </div>
              <div>
                <p className="mb-2">Confirm New Password</p>
                <input
                  type="password"
                  name="confirmNewPassword"
                  value={passwordData.confirmNewPassword}
                  onChange={handlePasswordInputChange}
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px]"
                  placeholder="Confirm new password"
                />
              </div>
              <button
                onClick={handleChangePassword}
                disabled={isPasswordChanging}
                className="bg-[#52bbab] px-12 rounded-[14px] text-[#fff] py-3 w-full flex items-center justify-center"
              >
                {isPasswordChanging ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Changing...
                  </div>
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="bg-[#fff] p-6 rounded-[16px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-semibold">Profile</h1>
        </div>
        <p className="text-[14px] text-[#9e9b9b] mt-2">
          View and edit your profile information. Manage your personal details,
          profile picture, and account settings to ensure your information is
          up-to-date and tailored to your preferences
        </p>
        <div className="p-4 flex items-center justify-between bg-[#fafafa] border rounded-[14px] mt-6">
          <div className="flex items-center gap-4">
            <img
              className="h-[75px] w-[75px] rounded-full"
              src={user.profilePic || selectedImage || ProfileImage}
              alt="Profile"
            />
            <div>
              <h1 className="text-[20px] font-semibold">{}</h1>
              <p className="text-[#a4a4a4]">
                {profileData.email || "lesliealexander@gmail.com"}
              </p>
            </div>
          </div>
          <label className="flex items-center gap-3 bg-[#e9e9e9] py-2 px-3 rounded-[25px] cursor-pointer">
            <img src={EditIcon} alt="Profile" className="h-[12px] w-[12px]" />
            <p className="text-[12px]">Change Profile Picture</p>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="hidden"
            />
          </label>
        </div>
        <div className="p-4">
          <h1 className="text-[20px] font-semibold mb-6">
            Personal Information
          </h1>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Full Name</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="fullName"
                  value={profileData.fullName}
                  onChange={handleInputChange}
                  placeholder="Enter Your Full Name"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Email Address</p>
                <input
                  disabled
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  placeholder="Enter Your Email Address"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Phone Number</p>
                <div className="flex gap-2">
                  <select
                    className="bg-[#eeeeee] w-[5rem] h-[51px] px-2 rounded-[14px] mt-3"
                    value={phoneCountryCode}
                    onChange={(e) => setPhoneCountryCode(e.target.value)}
                  >
                    {phoneCountryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                  <input
                    className="bg-[#eeeeee] w-3/5 h-[51px] px-4 rounded-[14px] mt-3"
                    type="text"
                    name="phoneNumber"
                    value={profileData?.phoneNumber || ""}
                    onChange={handleInputChange}
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Date of birth</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="date"
                  name="dateOfBirth"
                  value={profileData.dateOfBirth}
                  onChange={handleInputChange}
                  placeholder="Enter Your Date of birth"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Country</p>
                <select
                  className="bg-[#eeeeee]  w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="country"
                  value={profileData.country}
                  onChange={handleInputChange}
                >
                  <option value="">Select Country</option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                </select>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>State</p>
                <select
                  className="bg-[#eeeeee]  w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="state"
                  value={profileData.state}
                  onChange={handleInputChange}
                >
                  <option value="">Select State</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="California">California</option>
                </select>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>City</p>
                <select
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  name="city"
                  value={profileData.city}
                  onChange={handleInputChange}
                >
                  {/* {profileData.city && <option selected value="">{profileData.city}</option>} */}
                  <option value="">Select City</option>
                  <option value="Ahmedabad">Ahmedabad</option>
                  <option value="Los Angeles">Los Angeles</option>
                </select>
              </div>
            </div>
            <div className="col-span-4 pr-6">
              <div className="input-box">
                <p>Postal Code</p>
                <input
                  className="bg-[#eeeeee] w-full h-[51px] px-4 rounded-[14px] mt-3"
                  type="text"
                  name="postalCode"
                  defaultValue={profileData.postalCode}
                  onChange={handleInputChange}
                  placeholder="Enter Your Code"
                />
              </div>
            </div>
            <div className="col-span-4 pr-6 flex items-end justify-end gap-x-4">
              <button
                onClick={() => setIsPasswordModalOpen(true)}
                className="bg-[#52bbab] px-6 rounded-[14px] text-[#fff] py-3"
              >
                Change Password
              </button>
              <button
                onClick={saveProfile}
                className="bg-[#52bbab] px-6 rounded-[14px] text-[#fff] py-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
