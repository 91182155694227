import React, { useEffect, useState } from "react";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import { ProfileResponse } from "../../ProjectManager/Components/ProjectManager";
import { useGetProjectApplication } from "../useGetProjectApplication";
import ProjectDetails from "./ProjectDetails";
import { useNavigate } from "react-router-dom";

export interface Application {
  _id: string;
  userId: ProfileResponse;
  phoneNumber: string;
  name: string;
  geographicMarket: string;
  postalCode: string;
  symbol: string;
  email: string;
  ticker: string;
  category: string;
  description: string;
  otherDocument: string[];
  images: string[];
  requiredInvestment: number;
  currenciesAccepted: [
    {
      _id: string;
      name: string;
      symbol: string;
    },
  ];
  projectPhases: { phaseName: string; phaseDescription: string }[];
  auditReports: string;
  compliance: string[];
  lockInPeriod: number;
  maxSupply: number;
  website: string;
  owner: string;
  burn: number;
  minInvestment: number;
  projectType: string;
  projectStage: string;
  balanceSheet: string;
  logo: string;
  approvalStatus: string;
  contractAddress: string;
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalDocs: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const ProjectApplication = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const {
    getAllProjects,
    rejectApplication,
    acceptApplication,
    getRejectedApplication,
    getAcceptedApplication,
    loading,
  } = useGetProjectApplication();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [selectedProject, setSelectedProject] = useState<Application | null>(
    null,
  );
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("normal");

  const [pagination, setPagination] = useState<PaginationState>({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  const navigate = useNavigate();

  const handleNavigation = (projectId: string) => {
    navigate(`/projectapplication/${projectId}`);
  };

  useEffect(() => {
    async function fetchProjects() {
      try {
        let response: any;
        if (filter === "normal") {
          response = await getAllProjects(pagination.currentPage.toString());
        } else if (filter === "rejected") {
          response = await getRejectedApplication(
            pagination.currentPage.toString(),
          );
        } else {
          response = await getAcceptedApplication(
            pagination.currentPage.toString(),
          );
        }
        if (response) {
          console.log(response);
          setApplications(response.docs || []);
          setPagination((prev) => ({
            ...prev,
            totalPages: response.totalPages || 1,
            totalDocs: response.totalDocs || 0,
            hasNextPage: response.hasNextPage || false,
            hasPrevPage: response.hasPrevPage || false,
          }));
        }
      } catch (error) {
        console.error("Failed to fetch projects", error);
      }
    }

    fetchProjects();
  }, [pagination.currentPage, filter]);

  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const LoaderModal: React.FC = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-lg">
        <p className="text-lg font-semibold text-gray-700">Processing...</p>
      </div>
    </div>
  );

  return (
    <>
      <div className="bg-[#fff] min-h-full p-4 rounded-[16px]">
        <h1 className="text-[20px] font-semibold">Withdraw Applications</h1>
        <p className="text-[14px] text-[#9e9b9b] mt-2">
          View a list of appications supporting your projects. Gain insights
          into their contributions and engagement, fostering connections and
          opportunities for collaboration.
        </p>
        <div className="flex justify-end mb-4">
          <select
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="border px-3 py-2 rounded-lg"
          >
            <option value="normal">Active Applications</option>
            <option value="rejected">Rejected Applications</option>
            <option value="accepted">Accepted Applications</option>
          </select>
        </div>

        {loading ? (
          <div className="w-full h-[16rem] flex justify-center items-center">
            <p>Loading..</p>
          </div>
        ) : (
          <>
            {applications && applications.length > 0 ? (
              <div className="bg-white border rounded-lg overflow-hidden mt-4">
                <h1 className="ml-3 my-3 font-semibold">Active Investors</h1>
                <div className="overflow-x-auto">
                  <table className="min-w-full shadow-sm">
                    <thead>
                      <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                        <th className="py-3 px-6 text-left">Sr. No.</th>
                        <th className="py-3 px-6 text-left">Project Name</th>
                        <th className="py-3 px-6 text-left">Min Investment</th>
                        <th className="py-3 px-6 text-left">Lock In Period</th>
                        <th className="py-3 px-6 text-left"></th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {applications.map((project, index) => (
                        <tr
                          key={project._id}
                          className="border-b border-gray-200 hover:bg-gray-50"
                          onClick={() => handleNavigation(project._id)} // Set selected project on click
                        >
                          <td className="py-3 px-6">
                            {(pagination.currentPage - 1) * 4 + index + 1}
                          </td>
                          <td className="py-3 px-6">{project?.name}</td>
                          <td className="py-3 px-6">
                            {project?.minInvestment}
                          </td>
                          <td className="py-3 px-6">{project.lockInPeriod}</td>
                          <td className="py-3 px-6 text-green-400 hover:cursor-pointer">
                            View
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-full h-[16rem] flex items-center justify-center">
                <p className="text-xl">No Application Found</p>
              </div>
            )}

            {/* Pagination */}
            {applications.length > 0 && (
              <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
                <button
                  onClick={handlePrevPage}
                  disabled={!pagination.hasPrevPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasPrevPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={prevButton}
                    alt="Previous"
                  />
                </button>

                {getPageNumbers().map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${pagination.currentPage === pageNumber ? "bg-[#56BBAC] text-white" : ""}`}
                  >
                    {pageNumber}
                  </button>
                ))}

                <button
                  onClick={handleNextPage}
                  disabled={!pagination.hasNextPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasNextPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={nextButton}
                    alt="Next"
                  />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProjectApplication;
