import React, { useEffect } from "react";
import RightIcon from "../../../images/Settings/right.png";
import RightIcon2 from "../../../images/Settings/right2.png";
import KycImage from "../../../images/Settings/kyc.png";
import "../Components/styles.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import toast from "react-hot-toast";

const Notification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [unSubscribeEmail, setUnSubscribeEmail] = useState(false);
  const [unSubscribeInApp, setUnSubscribeInApp] = useState(false);

  useEffect(() => {
    // Fetch current notification settings when the component mounts
    getNotificationSettings();
  }, []);

  const getNotificationSettings = async () => {
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.getNotifications,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);

      if (response.success) {
        console.log(response.response.unsubscribedEmail);
        setUnSubscribeEmail(response.response.unsubscribedEmail);
        setUnSubscribeInApp(response.response.unsubscribedInApp);
      } else {
        toast.error("Failed to fetch notification settings.");
      }
    } catch (err) {
      console.error("Error fetching notification settings:", err);
    }
  };

  const changeNotificationSettings = async (email: boolean, inApp: boolean) => {
    try {
      const response = await ApiService({
        method: "POST",
        endpoint: apiPaths.subscribeNotification,
        headers: {
          "Content-Type": "application/json",
        },
        data: { unSubscribeEmail: email, unSubscribeInApp: inApp },
      });

      return response;
    } catch (err) {
      console.error("Error updating notification settings:", err);
      throw err;
    }
  };

  const handleEmailToggle = async () => {
    const updatedEmailState = !unSubscribeEmail;
    setUnSubscribeEmail(updatedEmailState);
    await handleToggleChange(updatedEmailState, unSubscribeInApp);
  };

  const handleInAppToggle = async () => {
    const updatedInAppState = !unSubscribeInApp;
    setUnSubscribeInApp(updatedInAppState);
    await handleToggleChange(unSubscribeEmail, updatedInAppState);
  };

  const handleToggleChange = async (email: boolean, inApp: boolean) => {
    setIsLoading(true);
    try {
      const response = await changeNotificationSettings(email, inApp);
      if (!response.success) {
        toast.error(
          "Failed to update notification settings. Please try again.",
        );
      }
      console.log(response);
    } catch (error) {
      toast("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="bg-[#fff] rounded-[16px] min-h-full">
        <div className="p-6 border-b-2">
          <div className="flex items-center gap-2 font-semibold">
            <Link to="/settings">
              <h1 className="mb-0 text-[20px]">Settings</h1>
            </Link>
            <img className="h-[20px] w-[20px]" src={RightIcon} alt="" />
            <h1 className="mb-0 text-[20px] text-[#52bbab]">Notification</h1>
          </div>
          <p className="text-[14px] text-[#9e9b9b]">
            Stay updated with real-time notifications. Get alerts on
            investments, project updates, and important platform activity to
            ensure you never miss a key moment.
          </p>
        </div>
        <div className="p-6 flex flex-col items-start justify-start">
          <div className="flex justify-between rounded-[8px] bg-[#f6f6f6] w-full h-[70px] items-center px-5">
            <p className="font-medium">In-App Notification</p>
            <button
              disabled={isLoading}
              className={isLoading ? "opacity-50" : ""}
              onClick={handleInAppToggle}
            >
              <label className="switch">
                <input type="checkbox" checked={unSubscribeInApp} readOnly />
                <span className="slider"></span>
              </label>
            </button>
          </div>
          <div className="mt-6 flex justify-between rounded-[8px] bg-[#f6f6f6] w-full h-[70px] items-center px-5">
            <p className="font-medium">Email Notification</p>
            <button
              disabled={isLoading}
              className={isLoading ? "opacity-50" : ""}
              onClick={handleEmailToggle}
            >
              <label className="switch">
                <input type="checkbox" checked={unSubscribeEmail} readOnly />
                <span className="slider"></span>
              </label>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
