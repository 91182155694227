import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { LinksArray } from "./MockSidebar";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setLogOutModal: (arg: boolean) => void;
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setLogOutModal,
}: SidebarProps) => {
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  // Handle click outside the sidebar to close it
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarOpen]);

  // Handle Escape key to close the sidebar
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [sidebarOpen]);

  const clickHandler = (name: string) => {
    if (name.toLocaleLowerCase() === "logout") {
      setLogOutModal(true);
    }
  };

  // Render the sidebar links

  const renderLinks = (linksArray: any[]) => (
    <>
      {linksArray.map((linkItem, index) => {
        if (linkItem.name === "Logout") {
          return (
            <button
              key={index}
              onClick={(e) => {
                clickHandler(linkItem.name);
                e.stopPropagation();
              }}
              className="link-button px-4 py-[18px] rounded-[10px] text-white"
            >
              <div className="flex gap-6 items-center">
                {linkItem.img}
                <p className="text-white text-[15px]">{linkItem.name}</p>
              </div>
            </button>
          );
        }

        return (
          <NavLink
            key={index}
            to={linkItem.link}
            className={({ isActive }) =>
              `link-button px-4 py-[18px] rounded-[10px] ${
                isActive ? "bg-[#5dcbba]" : ""
              }`
            }
            onClick={(e) => {
              clickHandler(linkItem.name);
              e.stopPropagation();
            }}
          >
            <div className="flex gap-6 items-center">
              {linkItem.img}
              <p className="text-white text-[15px]">{linkItem.name}</p>
            </div>
          </NavLink>
        );
      })}
    </>
  );

  // scroll to add class
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={`w-full sticky top-[20px] bg-[#52bbab] fit-to-screen rounded-[20px] ${
        scrollPosition >= 10 ? "fulled-screen" : ""
      }`}
    >
      <div className="flex flex-col h-full p-6 rounded-[20px] min-h-[500px]">
        {renderLinks(LinksArray)}
      </div>
    </section>
  );
};

export default Sidebar;
