import React, { useState } from "react";
import { useSignup } from "./useSignup"; // Import the custom signup hook
import signupImage from "../../../images/login/signup.png";
import facebook from "../../../images/login/facebook.png";
import google from "../../../images/login/google.png";
import apple from "../../../images/login/apple.png";
import LoginLogo from "../../../images/login/loginLogo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Signup = () => {
  const { signup, verifyPassword, loading } = useSignup();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Update form data on input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { fullName, email, password, confirmPassword } = formData;

    // Verify passwords match
    if (!verifyPassword(password, confirmPassword)) {
      return;
    }

    // Call signup API
    await signup({ fullName, email, password });
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative pb-10 pt-20">
      <img
        className="absolute lg:top-7 lg:right-7 top-5 left-auto right-auto w-[210px]"
        src={LoginLogo}
        alt="Logo"
      />
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:px-10">
            <img
              className="lg:h-[100vh] max-w-full"
              src={signupImage}
              alt="Signup"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 flex items-center lg:px-10 lg:mt-0 mt-5">
            <div className="form-box w-full lg:px-10">
              <h3 className="login-heading mb-3">Sign up</h3>
              <p className="login-subText">
                Let’s get you all set up so you can access your account.
              </p>

              <form className="mx-auto mt-7" onSubmit={handleSubmit}>
                <div className="relative mb-5">
                  <input
                    type="text"
                    id="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none peer"
                    placeholder="Full Name"
                    required
                  />
                  <label
                    htmlFor="fullName"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Full Name
                  </label>
                </div>
                <div className="relative mb-5">
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Email"
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Email
                  </label>
                </div>

                {/* Password field with visibility toggle */}
                <div className="relative mb-5">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Password"
                    required
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Password
                  </label>
                  <span
                    className="absolute right-2 top-4 cursor-pointer text-sm"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEye size={20} />
                    ) : (
                      <FaEyeSlash size={20} />
                    )}
                  </span>
                </div>

                {/* Confirm Password field with visibility toggle */}
                <div className="relative mb-5">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 rounded-[4px] border border-black appearance-none focus:outline-none"
                    placeholder="Confirm Password"
                    required
                  />
                  <label
                    htmlFor="confirmPassword"
                    className="absolute text-sm text-black duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white px-2 left-2"
                  >
                    Confirm Password
                  </label>
                  <span
                    className="absolute right-2 top-4 cursor-pointer text-sm"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <FaEye size={20} />
                    ) : (
                      <FaEyeSlash size={20} />
                    )}
                  </span>
                </div>

                <div className="flex items-start mb-5">
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                        required
                      />
                      <label className="ms-2 text-sm font-medium text-gray-900">
                        I agree to all the{" "}
                        <span className="text-[var(--primary-color)]">
                          Terms
                        </span>{" "}
                        and{" "}
                        <span className="text-[var(--primary-color)]">
                          Privacy Policies
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-white min-w-full bg-[var(--primary-color)] focus:outline-none font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
                >
                  Create Account
                </button>
              </form>

              <p className="text-center mt-5">
                Already have an account?
                <span className="text-[var(--primary-color)] cursor-pointer">
                  {" "}
                  Login
                </span>
              </p>

              <p className="two-side-line mt-7">Or login with</p>
              <div className="grid grid-cols-12 mt-4">
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={facebook} alt="Facebook" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={google} alt="Google" />
                  </button>
                </div>
                <div className="col-span-4 px-2">
                  <button className="login-icon-btn py-2 rounded-[4px] flex items-center justify-center border border-[#52bbab] w-full">
                    <img width={24} src={apple} alt="Apple" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
