// src/hooks/useLogin.ts
import { useState } from "react";
import { LoginRequest } from "./Login.props";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { setIsLoggedIn, setUser } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const login = async (credentials: LoginRequest): Promise<any | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: any = await ApiService({
        method: "POST",
        endpoint: apiPaths.Login,
        data: credentials,
      });

      console.log("LOGIN RESPONSE : ");
      console.log(response.response);
      // Cookies.set('admin_accessToken',response.response.accessToken)
      // Cookies.set('admin_refreshToken',response.response.refreshToken)
      dispatch(setUser(response.response));
      dispatch(setIsLoggedIn(true));

      // Handle successful login
      toast.success("Login successful!", { duration: 5000 });
      return response; // Return the login response data
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      toast.error("Login error:", error);
      // console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};
