// src/components/Authentication/ForgotPassword/useForgot.ts
import { useState } from "react";
import { ForgotRequest, ForgotResponse } from "./Forgot.props";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../redux/signupSlice";

const useForgot = () => {
  const [email, setEmail] = useState<string>(""); // State for email
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to handle the password recovery request
  const forgotPassword = async (
    credentials: ForgotRequest,
  ): Promise<ForgotResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: ForgotResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.ForgotPassword,
        data: credentials,
      });

      return response;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to recover password";
      setError(errorMessage);
      console.error("Forgot password error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    dispatch(setUserDetails({ email: email }));
    const response = await forgotPassword({ email }); // Pass the email to forgotPassword
    if (response) {
      // Navigate to /verify on success
      navigate("/verify");
    }
  };

  return { email, setEmail, loading, error, handleSubmit };
};

export default useForgot;
