import React, { useEffect, useState } from "react";
import { FileText, Mail, Briefcase, Loader2, Globe } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useGetApplication from "../useGetApplication";
import { ProfileResponse } from "../../Profile/Profile.props";
import { Application } from "../../ProjectApplication/Components/ProjectApplication";
import { FaMoneyBill } from "react-icons/fa";

interface WithDrawApplication {
  _id: string;
  userId: ProfileResponse;
  title: string;
  description: string;
  projectId: Application & {
    website?: string;
  };
  receipt: string;
  status: string;
  amount: number;
}

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [formData, setFormData] = useState<WithDrawApplication>();
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [reason, setReason] = useState("");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const { getProjectById, acceptApplication, rejectApplication } =
    useGetApplication();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await getProjectById(String(projectId));
        setFormData(response);
      } catch (error) {
        console.log("Some Error occurred While Fetching project detail by ID");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  const onBack = () => {
    navigate("/withdrawapplication");
  };

  const handleAction = async () => {
    if (!formData) return;

    setIsProcessing(true);

    try {
      if (!actionType) {
        if (!reason || reason.length === 0) {
          toast.error("Reason Is Required");
          return;
        }
      }
      let resp;

      if (actionType === "approve") {
        resp = await acceptApplication(formData._id);
      } else {
        resp = await rejectApplication(formData._id, reason);
      }
      if (resp) {
        toast.success(
          actionType === "approve"
            ? "Project approved successfully!"
            : "Project rejected successfully!",
        );
      } else {
      }
    } catch (error) {
    } finally {
      onBack();
      setIsProcessing(false);
      setShowConfirmationModal(false);
      setActionType(null);
      setReason("");
    }
  };

  const initiateAction = (type: "approve" | "reject") => {
    setActionType(type);
    setShowConfirmationModal(true);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-orange-500";
      default:
        return "";
    }
  };

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        No Data Found, Please check the Project ID again.
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Withdrawal Application
          <span className={`${getStatusColor(formData.status)} text-sm ml-2`}>
            ({formData.status})
          </span>
        </h1>
        <p className="text-gray-500">
          View and manage withdrawal applications for your projects. Review
          details and process requests efficiently.
        </p>
      </header>

      <section className="space-y-6">
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Briefcase className="w-5 h-5 mr-2 text-emerald-600" />
            Application Information
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Title</label>
                <p className="font-medium">{formData.title}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Owner Name</label>
                <p className="font-medium">{formData.userId.fullName}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Project Name</label>
                <p className="font-medium">{formData.projectId.name}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FaMoneyBill className="w-4 h-4 mr-1" />
                  Amount
                </label>
                <p className="font-medium">
                  {formData.amount?.toLocaleString() || 0}
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Mail className="w-4 h-4 mr-1" />
                  Email Address
                </label>
                <p className="font-medium">{formData.userId.email}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Globe className="w-4 h-4 mr-1" />
                  Website
                </label>
                {formData.projectId.website ? (
                  <a
                    href={formData.projectId.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-600 hover:underline"
                  >
                    {formData.projectId.website}
                  </a>
                ) : (
                  <p className="text-gray-500">Not provided</p>
                )}
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FileText className="w-4 h-4 mr-1" />
                  Receipt
                </label>
                <a
                  href={formData.receipt}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-emerald-600 hover:underline"
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <label className="text-gray-500 text-sm">Description</label>
            <div className="mt-2 ">
              <div
                className={`prose max-w-none ${
                  !isDescriptionExpanded ? "line-clamp-3" : ""
                }`}
              >
                {formData.description}
              </div>
              {formData.description.length > 150 && (
                <button
                  onClick={() =>
                    setIsDescriptionExpanded(!isDescriptionExpanded)
                  }
                  className="text-emerald-600 hover:underline mt-2 text-sm"
                >
                  {isDescriptionExpanded ? "Show less" : "Read more"}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between pt-4 border-t">
          <button
            onClick={onBack}
            disabled={isProcessing}
            className="text-emerald-600 hover:underline"
          >
            ← Back
          </button>

          {formData.status === "pending" && (
            <div className="space-x-3">
              <button
                onClick={() => initiateAction("approve")}
                disabled={isProcessing}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50"
              >
                Approve
              </button>

              <button
                onClick={() => initiateAction("reject")}
                disabled={isProcessing}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50"
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </section>

      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to {actionType} this application?
            </p>

            {actionType === "reject" && (
              <div className="mb-4">
                <label className="text-sm text-gray-500">
                  Reason for rejection
                </label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Provide a reason"
                  className="w-full p-2 mt-2 border rounded-md"
                  rows={4}
                />
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                disabled={isProcessing}
                onClick={() => {
                  setShowConfirmationModal(false);
                  setActionType(null);
                  setReason("");
                }}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                disabled={isProcessing}
                onClick={handleAction}
                className={`px-4 py-2 rounded-lg relative ${
                  actionType === "approve"
                    ? "bg-emerald-600 hover:bg-emerald-700"
                    : "bg-red-500 hover:bg-red-600"
                } text-white disabled:opacity-50`}
              >
                {isProcessing ? (
                  <span className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationDetails;
